import React, { useState, useEffect, ReactElement, useRef } from 'react'
import { navigate, Link } from 'gatsby'
import clsx from 'clsx'
import { useLocation } from '@reach/router'

import EmptyStateIcon from '@static/img/icons/search-empty-state.svg'

// import TrackingContext from '@providers/trackingProvider'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import { DocumentReference, Index, SerialisedIndexData } from 'elasticlunr'
import queryString from 'query-string'

import useGlobalText from '@hooks/useGlobalText'

import Tabs from '@objects/tabs'
import Icon from '@objects/icon'
import Pagination from '@objects/pagination'
import SearchInput from '@objects/searchinput'
import Headline from '@objects/headline'
import Paragraph from '@objects/paragraph'
import { FormattedMessage } from 'react-intl'
import { useIntl } from 'react-intl'
import FontSize from '@config/theme/definitions/fontSize'

const useStyles = makeStyles((theme) => ({
  headline: {
    marginBottom: theme.spacing(10),
    // this headline should read as an h1 for accessibility but have the font size of an h2:
    fontSize: FontSize['3xl'],
    [theme.breakpoints.up('lg')]: {
      fontSize: FontSize['5xl'],
    },
  },
  resultsRoot: {
    padding: theme.spacing(15, 0, 17, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(20, 0, 17, 0),
    },
    '& ul': {
      listStyleType: 'none',
      padding: '0',
      margin: '0',
    },
  },
  results: {
    paddingTop: theme.spacing(8, 4, 0, 4),
  },
  resultContent: {
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  resultList: {
    minHeight: theme.spacing(100),
  },
  result: {
    display: 'block',
    borderBottom: `1px solid ${theme.palette.button.disabled}`,
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 4),
      '&:hover': {
        backgroundColor: theme.palette.background.light,
        '& $resultContent': {
          textDecoration: 'underline',
        },
      },
    },
  },
  resultHead: {
    marginBottom: theme.spacing(1),
    ...theme.typography.helpertext,
  },
  resultHeadline: {
    ...theme.typography.body1,
    margin: theme.spacing(1, 0),
    fontWeight: 'bold',
  },
  resultCopy: {
    ...theme.typography.body1,
    margin: 0,
  },
  noresults: {
    padding: theme.spacing(17, 4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  noresultsMessage: {
    textAlign: 'center',
  },
  resultEmptyStateIcon: {
    width: '218px',
    height: 'auto',
  },
  pagination: {
    margin: theme.spacing(15, 0, 0, 0),
  },
}))

interface IFooter {
  className?: string
  searchIndex: SerialisedIndexData<RvG.SearchResult>
}

export default function SearchResults({
  className,
  searchIndex,
}: IFooter): ReactElement {
  const location = useLocation()
  const classes = useStyles()
  const { getText } = useGlobalText()
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>
  // const TrackingCtx = useContext(TrackingContext)
  const [index, setIndex] = useState<Index<RvG.SearchResult> | any>(
    null as unknown as Index<RvG.SearchResult>
  )
  const intl = useIntl()

  const [query, setQuery] = useState<string>('')
  const [resultsAll, setResultsAll] = useState([])
  const [resultsArticle, setResultsArticle] = useState([])
  const [resultsPage, setResultsPage] = useState([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const ITEMS_PER_PAGE = 15

  function search(searchquery: string | string[]) {
    setResultsAll(
      index
        .search(searchquery, { expand: true })
        .map(({ ref }: { ref: DocumentReference }) =>
          index.documentStore.getDoc(ref)
        )
        .filter(
          (result: RvG.SearchResult) => result.locale === 'de' && !!result.path
        )
    )

    navigate(`${location.pathname}#searchquery=${searchquery}`, {
      replace: true,
    })

    setPageTitle(searchquery)
  }

  function setPageTitle(searchQ: string | string[]): void {
    document.title = !searchQ
      ? 'Suche | Runter vom Gas'
      : `Suchergebnisse für '${searchQ}' | Runter vom Gas`
  }

  function TabHeadClickEvent(name: string) {
    // TrackingCtx.PushMessage('custom.search-tabchanged', {
    //   message: name,
    // })
  }

  function onChangePage(e: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value)
    window.scrollTo({
      top: ref.current.getBoundingClientRect().y + window.scrollY - 100,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (!index && searchIndex) {
      setIndex(Index.load(searchIndex))
    }
  }, [])

  useEffect(() => {
    let qString = queryString.parse(location?.hash)?.searchquery
    if (Array.isArray(qString)) {
      qString = qString.join(' ')
    }

    setQuery(qString || '')
    setCurrentPage(1)
  }, [location.hash])

  useEffect(() => {
    if (!query.length) return
    search(query)
  }, [query])

  useEffect(() => {
    setResultsArticle(
      resultsAll.filter((result: RvG.SearchResult) => result.type === 'article')
    )
    setResultsPage(
      resultsAll.filter((result: RvG.SearchResult) => result.type === 'page')
    )
  }, [resultsAll])

  function results(results: RvG.SearchResult[]) {
    return results.map((result, i) => {
      const date = new Date(
        result.publishdate || result.updatedAt || result.createdAt
      )
      const formattedDate = `${('0' + date.getDate()).slice(-2)}.${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}.${date.getFullYear()}`
      return (
        <li key={`${result.path}${i}`}>
          <Link className={classes.result} to={result.path}>
            <Box display="flex" flexDirection="row">
              <Box flexGrow={1} className={classes.resultHead}>
                {result.type === 'article' && getText('searchCategoryArticle')}
                {result.type === 'page' && getText('searchCategoryPages')}
              </Box>
              <Box flexShrink={0} className={classes.resultHead}>
                {formattedDate}
              </Box>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box flexGrow={1} className={classes.resultContent}>
                <Paragraph className={classes.resultHeadline}>
                  {result.title}
                </Paragraph>
                {Object.keys(result.copy).length > 1 && (
                  <Paragraph className={classes.resultCopy} truncate={45}>
                    {result.teaserCopy}
                  </Paragraph>
                )}
              </Box>
              <Box
                display="flex"
                flexGrow={1}
                flexShrink={0}
                alignItems="center"
              >
                <Icon name="ChevronRight" />
              </Box>
            </Box>
          </Link>
        </li>
      )
    })
  }

  function resultList(resultArray: RvG.SearchResult[]) {
    const handleAriaLabel = (type: string, page: number) => {
      if (type === 'next') {
        return intl.formatMessage({
          id: 'pagination.label.nextPage',
        })
      }

      if (type === 'previous') {
        return intl.formatMessage({
          id: 'pagination.label.previousPage',
        })
      }
      return (
        intl.formatMessage({
          id: 'pagination.label.goToPage',
        }) + page
      )
    }

    return (
      <>
        {results(
          resultArray.slice(
            ITEMS_PER_PAGE * (currentPage - 1),
            ITEMS_PER_PAGE * (currentPage - 1) + ITEMS_PER_PAGE
          )
        )}
        {resultArray.length > ITEMS_PER_PAGE && (
          <Pagination
            count={Math.ceil(resultArray.length / ITEMS_PER_PAGE)}
            page={currentPage}
            onChange={onChangePage}
            className={classes.pagination}
            getItemAriaLabel={handleAriaLabel}
          />
        )}
      </>
    )
  }

  return (
    <section
      ref={ref}
      className={clsx(className, classes.resultsRoot)}
      role="status"
    >
      <Headline className={classes.headline} level={1}>
        {resultsAll.length > 0
          ? `${resultsAll.length} ${getText(
              'searchResultsHeadline',
              'query',
              query
            )}`
          : `0 Treffer ${query && `für „${query}“`}`}
      </Headline>
      <SearchInput
        resultsAll={resultsAll}
        searchfunc={search}
        searchquery={query}
        noautosuggest
      />
      {resultsAll.length > 0 ? (
        <div className={classes.resultList}>
          <Tabs
            className={classes.result}
            type="radio"
            tabHeads={[
              {
                label: `${getText('searchCategoryAll')} (${resultsAll.length})`,
                onClick: () => {
                  TabHeadClickEvent('resultsAll')
                  setCurrentPage(1)
                },
              },
              {
                label: `${getText('searchCategoryPages')} (${
                  resultsPage.length
                })`,
                onClick: () => {
                  TabHeadClickEvent('resultsPage')
                  setCurrentPage(1)
                },
              },
              {
                label: `${getText('searchCategoryArticle')} (${
                  resultsArticle.length
                })`,
                onClick: () => {
                  TabHeadClickEvent('resultsArticle')
                  setCurrentPage(1)
                },
              },
            ]}
            active={0}
            ariaLabel={intl.formatMessage({
              id: 'search.result.aria.tabs',
            })}
          >
            <ul>{resultList(resultsAll)}</ul>
            <ul>{resultList(resultsPage)}</ul>
            <ul>{resultList(resultsArticle)}</ul>
          </Tabs>
        </div>
      ) : (
        <div className={classes.noresults}>
          <div className={classes.noresultsMessage}>
            <Headline level={3}>
              <FormattedMessage id={'search.noresult'} />
            </Headline>
          </div>
          <img
            src={EmptyStateIcon}
            className={classes.resultEmptyStateIcon}
            alt={''}
          />
        </div>
      )}
    </section>
  )
}
