import React, { useState, useEffect, ReactElement } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'
import MuiTabs from '@material-ui/core/Tabs'
import TabHead, { TabProps } from '@material-ui/core/Tab'
import TabPanel from './tabpanel'

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabHeads: {
    margin: theme.spacing(6, 0, 10, 0),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
    },
  },
  radio: {
    '& .MuiTab-root': {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      minWidth: 'auto',
      padding: 0,
      textTransform: 'none',
      ...theme.typography.body1,
      '&:not(:last-child)': {
        marginRight: theme.spacing(10),
      },
      [theme.breakpoints.down('sm')]: {
        minHeight: theme.spacing(10),
      },
    },
    '& .MuiTab-textColorInherit': {
      opacity: 1,
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: theme.spacing(5),
        height: theme.spacing(5),
        marginRight: theme.spacing(4),
        borderRadius: '50%',
        border: `2px solid ${theme.palette.button.main}`,
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        width: theme.spacing(3),
        height: theme.spacing(3),
        backgroundColor: theme.palette.button.tertiary,
        borderRadius: '50%',
        left: theme.spacing(1),
        top: '50%',
        transform: 'translateY(-50%)',
        opacity: 0,
        transition: `opacity ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`,
        '@media (prefers-contrast: more)': {
          border: `2px solid ${theme.palette.button.main}`,
        },
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'transparent !important',
      color: 'inherit !important',
    },
    '& .Mui-selected .MuiTab-wrapper::after': {
      opacity: 1,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-flexContainer': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  },
  simple: {
    '& .MuiTab-root': {
      fontFamily: 'inherit',
      minWidth: 'auto',
      minHeight: 'auto',
      padding: theme.spacing(0, 2),
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('md')]: {
        minWidth: theme.spacing(9),
      },
    },
    '& .Mui-selected': {
      color: theme.palette.error.main,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-flexContainer': {
      // justifyContent: 'space-between',
      margin: theme.spacing(0, -2),
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      },
    },
  },
}))

export type TabsProps = RvG.IReactDefaultProps & {
  tabHeads: Array<TabProps>
  type?: 'simple' | 'radio'
  active: number
  ariaLabel?: string
}

export default function Tabs({
  children,
  tabHeads,
  type,
  active,
  ariaLabel,
}: TabsProps): ReactElement {
  const classes = useStyles()
  const [value, setValue] = useState(
    active || tabHeads.findIndex((h) => !h.disabled)
  )

  useEffect(() => {
    setValue(active)
  }, [active])

  function handleChange(
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) {
    setValue(newValue)
  }

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    }
  }

  function renderPanels() {
    return (children as Array<React.ReactNode>)?.map((child, i) => {
      return (
        <TabPanel key={`tabPanel${i}`} index={i} value={value}>
          {child}
        </TabPanel>
      )
    })
  }

  function renderHeads() {
    return tabHeads.map((head, i) => {
      return (
        <TabHead
          key={`${head.label}${i}`}
          label={head.label}
          onClick={head.onClick}
          disabled={head.disabled}
          {...a11yProps(i)}
        />
      )
    })
  }

  return (
    <div className={classes.tabsRoot}>
      <MuiTabs
        className={clsx(
          classes.tabHeads,
          type ? classes[type] : classes.simple
        )}
        scrollButtons="off"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label={ariaLabel}
      >
        {renderHeads()}
      </MuiTabs>
      {renderPanels()}
    </div>
  )
}
