import React from 'react'

export type TabPanelProps = RvG.IReactDefaultProps & {
  index: number
  value: React.ReactNode
}

export default function TabPanel({
  children,
  value,
  index,
}: TabPanelProps): React.ReactElement {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && children}
    </div>
  )
}
