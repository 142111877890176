import React, { ReactElement } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Index } from 'elasticlunr'

import useGlobalText from '@hooks/useGlobalText'

import MetaTags from '@objects/metatags'
import SearchResults from '@components/searchresults'
import Container from '@objects/container'
import BreadCrumbs from '@objects/breadcrumb'

export type SearchResultProps = {
  data: {
    siteSearchIndex: {
      index: Index<RvG.SearchResult>
    }
  }
  pageContext: RvG.PageHelpers.PageContext
}
export default function RootIndex({
  data,
  pageContext,
}: SearchResultProps): ReactElement {
  const searchIndex = get(data, 'siteSearchIndex.index')
  const { getText } = useGlobalText()
  return (
    <>
      <MetaTags locale={pageContext.locale} meta={{ noIndex: true }} />
      <Container background="light" type="nomargin">
        <BreadCrumbs crumbList={[{ label: getText('searchText') }]} />
      </Container>
      <Container width="md" type="nomargin">
        <SearchResults searchIndex={searchIndex} />
      </Container>
    </>
  )
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export const pageQuery = graphql`
  query SearchResultPageIndexQuery {
    siteSearchIndex {
      index
    }
  }
`
